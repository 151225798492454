import axiosClient from "./axiosWrapper";
import logger from "../utils/logger";
// import {dateInputToDateString, HOURS_24_MS} from "../utils/time";
import {dateInputToDateString} from "../utils/time";

export const postMessage = async (message) => {
  let result = await axiosClient().post('/messages/send', message)

  return result
}

export const postConfirmation = async (message) => {
  let result = await axiosClient().post('/messages/confirm', message)

  return result
}

export const markMessageViewed = async (messageId) => {
  let result = await axiosClient().post('/messages/markViewed', {"messageId" : messageId})

  return result
}

export const getActualMessages = async (date) => { // existing messages
  logger('getActualMessages', date)
  // let setDate = date ? new Date(date) : new Date()
  let startDate = new Date(date)
  // let endDate = new Date(setDate)
  // endDate.setDate(endDate.getDate()+1)
  let result = await axiosClient().post(`/messages`, {startDate})
  logger('getActualMessages', result)
  return result && result.payload ? result.payload : []
}

export const getActualMessagesByDate = async (startDate, endDate) => { // existing messages
  logger('getActualMessages', startDate, endDate)
  let result = await axiosClient().post(`/messages`, {startDate, endDate})
  logger('getActualMessages by date', startDate, result)
  return result && result.payload ? result.payload : []
}

export const getMessage = async (messageId) => {
  let result = await axiosClient().get(`/messages/${messageId}`)
  return result
}

export const getTriggeringMessages = async (date) => {
  console.log('trig', date)
  let query = ''
  if (date) {
    let startDate = new Date(date)
    startDate.setDate(startDate.getDate() - 1)
    let endDate = new Date(date)
    endDate.setDate(endDate.getDate() + 1)
    query = `?fromDateTime=${dateInputToDateString(startDate.toISOString())}&toDateTime=${dateInputToDateString(endDate.toISOString())}`
  }

  let result = await axiosClient().get(`/messages/triggering${query}`)
  return result
}

export const MESSAGE_TYPE = Object.freeze({
  'INPUT_MESSAGE': 'input_message',
  'OUTPUT_MESSAGE': 'output_message',
  'INPUT_CONFIRMATION': 'input_confirmation',
  'OUTPUT_CONFIRMATION': 'output_confirmation'})

export const MESSAGE_TYPE_TITLES = Object.freeze({
  'input_message': 'Входящие данные',
  'output_message': 'Исходящие данные',
  'input_confirmation': 'Входящие подтверждения',
  'output_confirmation': 'Исходящие подтверждения'
})

export const MESSAGE_COLUMNS = Object.freeze({
  'date': 'Дата',
  'time': 'Время',
  'sender': 'Отправитель',
  'header': 'Заголовок',
})