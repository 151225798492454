import axiosClient from "./axiosWrapper";

//http://localhost:9091/api/v1/templates/message/mark
export const postMessageTypeChange = async (signalId, messageId, exercise) => {
  let result = await axiosClient().post('/templates/message/mark', {signalId, messageId, exercise})

  return result
}

export const getTemplateMessages = async (signalId) => { //message templates
  let result = await axiosClient().get(`/templates/messages${signalId ? '/' + signalId : ''}`)

  return result
}

export const getSeismologistTemplateMessages = async () => { //message templates for seismologist
  let result = await axiosClient().get(`/templates/seismologist_messages`)

  return result
}