<template>
  <div style="min-width: 260px; " class="wide-dialog">
    <md-field  style="width: 400px; max-width: 90vw; margin-bottom: 0" >
      <label for="role">{{template ? '' : 'Выберите шаблон'}}</label>
      <md-select v-model="templateIndex" name="template" id="template">
        <md-option disabled="" :value="null">Выберите шаблон</md-option>
        <md-option  v-for="(item, index) in templates" :key="item.name + '_' + index" :value="index" :title="item.name"  data-toggle="tooltip">{{item ? item.name : ''}}</md-option>
      </md-select>
    </md-field>
    <div><md-checkbox v-model="exercise" class="md-secondary" :disabled="signal ? signal.exercise : false" >Учебная {{signal && signal.exercise ? 'фиксировано от сигнала': ''}}</md-checkbox></div>
    <div style="display: flex;gap: 20px">
      <div class="" style="vertical-align: center; display: flex; align-items: center">{{template ? template.name : ''}}</div>

    </div>
    <br />
    <md-field disabled>
      <md-textarea disabled style="padding: 10px; width: 100%; min-height: 200px; "  v-model="header"></md-textarea>
    </md-field>
    <md-field >
      <md-textarea style="padding: 10px; width: 100%;min-height: 300px;font-family: monospace;"  v-model="text"></md-textarea>
    </md-field>
    <md-field disabled>
      <md-textarea disabled style="padding: 10px; width: 100%; min-height: 50px; font-family: monospace"   v-model="footer"></md-textarea>
    </md-field>
    <div style="display: flex; justify-content: flex-end">
      <md-button  @click="sendMessage" class="md-raised md-primary" style="margin-top: 40px">Отправить</md-button>
      <md-button  @click="closeForm" class="md-raised md-primary" style="margin-top: 40px">Отмена</md-button>
    </div>

  </div>
</template>

<script>
  import {getSeismologistTemplateMessages, getTemplateMessages} from "../../api/template-controller";
  import {postMessage} from "../../api/message-controller";
  import logger from "../../utils/logger";

  import {mapActions} from 'vuex'

  export default {
    name: 'TemplateMessageForm',
    props: {
      role: {
        type: String,
        default: () => {
          return 'oceanographer'
        }
      },
      signal: {
        type: Object,
        required: false,
        default: null
      }
    },
    data() {
      return {
        templates: [],
        template: null,
        templateIndex: null,
        exercise: false,
        header: null,
        text: null,
        footer: null
      }
    },
    watch: {
      templateIndex(newVal) {
        if (newVal >= 0) {
          this.template = this.templates[newVal]
          this.header = this.template.header
          this.text = this.template.content
          this.footer = this.template.footer
        }
      }
    },
    methods: {
      ...mapActions(['showInfoAlert']),
      closeForm() {
        this.$emit('closeTemplateMessageForm')
      },
      alertx(x) {
        this.showInfoAlert(x)
        this.closeForm()
      },
      async init() {
        let result
        console.log('template role', this.role)
        if (this.role === 'oceanographer') {
          result = await getTemplateMessages(this.signal ? this.signal.id : null)
        } else {
          result = await getSeismologistTemplateMessages()
        }

        logger('TemplateMessageForm', result)
        if (result.code) {
          this.templates = result.payload
          this.templateIndex = 0
        }
      },
      // updateTemplate() {
      //   if (this.templates) {
      //     this.header = this.templates.header
      //     this.text = this.templates.text
      //     this.footer = this.templates.footer
      //   }
      // }
      async sendMessage() {
        console.log('test send')
        let message = {exercise: this.exercise, header: this.header, footer: this.footer, content: this.text}
        let result = await postMessage(message)
        if (result && result.code) {
          this.alertx("Сообщение отправлено")
        }
      },
    },
    async mounted() {
      this.init()
    }

  }
</script>

<style scoped>
  .minvw {
    min-width: 50vw;
  }

  @media screen and (min-width: 1000px) {
    .wide-dialog {
      width: 800px;
    }
  }

  .md-field.md-has-textarea {
    padding-top: 0;
    margin-bottom: 0;
    margin-top: 2px;
  }
</style>