<template>
  <div class="main-container" >
    <div  class="main" style="margin: auto; display: flex; flex-direction: column; justify-content: center; align-items: center" >
      <h1>Регистрация </h1>
      <br />

      <md-field :class="{'md-invalid': !roleValid}"  style="width: 400px; max-width: 70vw; margin-bottom: 20px" >
        <label for="role">{{selectedRole ? 'Выбранная роль' : 'Выберите роль'}}</label>
        <md-select v-model="selectedRole" name="role" id="role">
          <md-option disabled="" :value="null">Выберите роль</md-option>
          <md-option  v-for="role in roles" :key="role.value" :value="role.value">{{role.title}}</md-option>
        </md-select>
      </md-field>
      <md-field :class="{'md-invalid': !organizationValid}"  style="width: 400px; max-width: 70vw; margin-bottom: 20px" >
        <label for="role">{{selectedOrganization ? 'Выбранная организация' : (selectedRole ? 'Выберите организацию' : 'Сначала выберете роль')}}</label>
        <md-select v-model="selectedOrganization" name="role" id="role">
          <md-option disabled="" :value="null">Выберите организацию</md-option>
          <md-option  v-for="org in limitedOrganizations" :key="org.value" :value="org.value" :disabled="org.disabled">{{org.title}}</md-option>
        </md-select>
      </md-field>
      <!--<br />-->
      <!--<div><b>2. Организация</b></div>-->
      <!--<select v-model="selectedOrganization" class="field" required-->
              <!--:class="{'invalid': !organizationValid}">-->
        <!--<option :value="null" selected disabled v-if="!selectedRole">Сначала выберете роль</option>-->
        <!--<option :value="null" selected disabled v-if="selectedRole">Выберите вашу организацию</option>-->
        <!--<option v-for="org in limitedOrganizations" :key="org.value" :value="org.value" :disabled="org.disabled" :selected="org.selected">{{org.title}}</option>-->
      <!--</select>-->

      <md-field style="width: 400px; max-width: 70vw; margin-bottom: 20px" :class="{'md-invalid': !lastNameValid}">
        <label>Фамилия</label>
        <md-input v-model.trim="lastName" placeholder="Фамилия" ></md-input>
        <span class="md-error">Фамилия не может быть пустой</span>
      </md-field>

      <md-field style="width: 400px; max-width: 70vw; margin-bottom: 20px" :class="{'md-invalid': !firstNameValid}">
        <label>Имя</label>
        <md-input v-model.trim="firstName" placeholder="Имя" ></md-input>
        <span class="md-error">Имя не может быть пустым</span>
      </md-field>

      <md-field style="width: 400px; max-width: 70vw; margin-bottom: 20px" >
        <label>Отчество</label>
        <md-input v-model.trim="patronymic" placeholder="Отчество" ></md-input>
      </md-field>

      <md-field style="width: 400px; max-width: 70vw; margin-bottom: 20px" :class="{'md-invalid': !phoneValid}">
        <label>Телефон</label>
        <md-input v-model.trim="phone" placeholder="Телефон" ></md-input>
        <span class="md-error">Телефон не может быть пустой</span>
      </md-field>

      <md-field style="width: 400px; max-width: 70vw; margin-bottom: 20px" :class="{'md-invalid': !emailValid}">
        <label>Логин (Почта)</label>
        <md-input v-model.trim="email" placeholder="почта (логин)" ></md-input>
        <span class="md-error">Почта должна быть валидной</span>
      </md-field>

      <md-field style="width: 400px; max-width: 70vw; margin-bottom: 20px" :class="{'md-invalid': !passwordValid}">
        <label>Пароль</label>
        <md-input v-model.trim="password" type="password" placeholder="Пароль" ></md-input>
        <span class="md-error">Пароль не может быть меньше 4х символов</span>
      </md-field>

      <br />

      <md-button  @click="register(false)" class="md-raised md-primary">Отправить</md-button>
      <br />

      <div ><a href="/signin"><h4 style="color:black; text-decoration: underline">вернуться на страницу входа</h4></a></div>
    </div>
  </div>
</template>

<script>

  const testName = /^[a-zA-Zа-яА-ЯёЁ]+[0-9a-zA-Zа-яёЁА-Я-\s\d]+$/i
  const emailTest =  /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/i
  const phoneTest = /^[+]{0,1}[0-9a-zA-Zа-яА-Я]+[a-zA-Zа-яА-Я\S\d]+$/i


  import {signup} from "../api/auth-controller";
  import {mapActions} from 'vuex'

  export default {
    name: "Registration",
    //Custom style for main and input for make the page responsive:
    props: {
      mainStyle: String,
    },
    computed: {
      limitedOrganizations: function () {
        let result = this.organizations.filter(org => org.roles.includes(this.selectedRole))
        // console.log('limited orgs', result, 'selected role', this.selectedRole)
        return result
      },
      organizationValid() {
        return !this.validationStarted || this.selectedOrganization //&& this.selectedOrganization === 'ФИАЦ'
      },
      roleValid() {
        return !this.validationStarted || this.selectedRole && this.roles.some(r => r.value === this.selectedRole)
      },
      lastNameValid() {
        return !this.validationStarted || this.lastName && testName.test(this.lastName)
      },
      firstNameValid() {
        return !this.validationStarted || this.firstName && testName.test(this.firstName)
      },
      patronymicValid() {
        return !this.validationStarted || this.patronymic && testName.test(this.patronymic)
      },
      emailValid() {
        return !this.validationStarted || this.email && emailTest.test(this.email)
      },
      phoneValid() {
        return !this.validationStarted || this.phone && phoneTest.test(this.phone)
      },
      passwordValid() {
        return !this.validationStarted || this.password && this.password.length > 3
      }
    },
    data() {
      return {
        passwordVisible: false,
        validationStarted: false,
        roles:  [
          // {value: 0, title: 'Выберите роль'},
          {order: 1, title: 'Администратор системы', value: 'administrator'},
          {order: 2, title: 'Океанолог', value: 'oceanographer'},
          {order: 3, title: 'Сейсмолог', value: 'seismologist'},
          {order: 4, title: 'Эксперт', value: 'expert'}
        ],
        selectedRole: null,
        organizations: [
          {value: "ФИАЦ",                    roles: ['administrator', 'expert'], title:'ФИАЦ', selected: true },
          {value: "Сахалинский центр цунами",  roles: ['administrator', 'oceanographer'], title:'Сахалинский центр цунами', disabled: false},
          {value: "Приморский центр цунами",  roles: ['administrator', 'oceanographer'], title:'Приморский центр цунами', disabled: false},
          {value: "Камчатский центр цунами", roles: ['administrator', 'oceanographer'], title:'Камчатский центр цунами', disabled: false},
          {value: "Камчатский сейсмоцентр", roles: ['administrator', 'seismologist'], title:'Камчатский сейсмоцентр', disabled: false},
          {value: "Сахалинский сейсмоцентр",  roles: ['administrator', 'seismologist'], title:'Сахалинский сейсмоцентр', disabled: false},
          {value: "Приморский сейсмоцентр",  roles: ['administrator', 'seismologist'], title:'Приморский сейсмоцентр', disabled: false},
        ],
        selectedOrganization: null,
        firstName: '',
        lastName: '',
        patronymic: '',
        phone: '',
        email: '',
        password: ''
      }
    },
    methods: {
      ...mapActions(['showInfoAlert']),
      validate() {
        this.validationStarted = true
        return this.organizationValid && this.roleValid && this.lastNameValid && this.firstNameValid  && this.emailValid && this.phoneValid && this.passwordValid
      },
      async register() {
        if (!this.validate()) {
          this.showInfoAlert('Пожалуйста, заполните все поля')
          return;
        }
        return signup(this.email, this.lastName, this.firstName, this.patronymic, this.phone, this.selectedOrganization, this.selectedRole, this.password )
          .then(result => {
            // console.log('signup result', JSON.stringify(result))
            if (result.code === 'SUCCESS') {
              this.$router.push('/signup-done')
            } else {

              // console.log('signup err', result)
              this.showInfoAlert(`Ошибка регистрации ${result.data.message}`)
            }
          })
          .catch(err => {
            console.log('signup err', err)
          })
      }
    }
  };
</script>

<style scoped>

  @media screen and (min-width: 800px) {
    .main-container {
      display: flex;
      margin: auto;
      height: 100%;
      width: 100%;
      max-width: 95vw;

      /*padding: 20px;*/
      /*width: 700px;*/
    }

    .main {
      padding: 15px 40px ;
      margin: auto;
      background: rgba(255, 255, 255, 0.6);
      text-align: center;
      border-radius: 3rem;
      box-shadow: 0 0 8px -5px #000000;
      max-width: 750px;
    }
  }

  .main-container {
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgba(0,0,0,0);
  }


  @media screen and (max-width: 799px) {
    .main {
      background: rgba(255, 255, 255, 0.6);
      text-align: center;
      border-radius: 3rem;
      box-shadow: 0 0 8px -5px #000000;
      max-width: 750px;
      padding: 20px 5px 20px 5px;
    }
  }

  h1 {
    cursor: default;
    user-select: none;
  }



  option {
    text-align: center;
  }

  select {
    max-width: 90vw;
  }

  input.inputText, input.button {
    border: none;
    text-align: center;
    outline: none;
    box-sizing: border-box;
    max-width: 90vw;
  }

  input:hover {
    box-shadow: 0px 0px 8px -5px #000000;
  }

  input:active {
    box-shadow: 0px 0px 8px -5px #000000;
  }

  #done {
    background: #98D0E8;
    min-width: 400px;
    width: 30%;
  }

  .button {
    cursor: pointer;
    user-select: none;
  }

  img {
    height: 2.2rem;
    margin: 10px;
    user-select: none;
  }

  img:hover {
    box-shadow: 0px 0px 8px -5px #000000;
    cursor: pointer;
    border-radius: 200rem;
  }

  #app .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1)
  }

  #app .dropdown li:last-child {
    border-bottom: none;
  }

  #app .dropdown li a {
    padding: 10px 20px;
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 1.25em;
  }

  #app .dropdown li a .fa {
    padding-right: 0.5em;
  }

  .invalid {
    border: 1px solid red !important;
  }



</style>
