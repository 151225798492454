<template>
  <div style="min-width: 260px; ">
    <h4>Составить сообщение</h4>
    <div style="display: flex;gap: 20px">
      <div class="" style="vertical-align: center; display: flex; align-items: center">{{action.name}}</div>
      <div><md-checkbox :checked="signalExercise" v-model="exercise"  class="md-secondary" :disabled="signalExercise" @click="changeVersionIfPossible()">Учебная {{ signalExercise ? '(Фиксировано)' : ''}}</md-checkbox></div>
    </div>
    <!--<div class="" style="vertical-align: center; display: flex; align-items: center">{{action}}</div>-->
    <!--<div class="" style="vertical-align: center; display: flex; align-items: center">{{template}}</div>-->
    <div>{{action.description}}</div>
    <div>{{message.name}}</div>
    <br />
    <div>
      <md-field disabled >
        <md-textarea style="padding: 10px; width: 100%; min-height: 200px" disabled v-model="message.header"></md-textarea>
      </md-field >
    </div>
    <div>
      <md-field >
        <md-textarea style="padding: 10px; width: 100%;min-height: 300px;font-family: monospace;"  v-model="message.content"></md-textarea>
      </md-field>
    </div>
    <div>
      <md-field disabled>
        <md-textarea style="padding: 10px; width: 100%; min-height: 50px; font-family: monospace"  disabled v-model="message.footer"></md-textarea>
      </md-field>
    </div>
    <div style="display: flex; justify-content: flex-end">
      <md-button  @click="sendMessage" class="md-raised md-primary" style="margin-top: 40px">Отправить</md-button>
      <md-button  @click="cancelMessage" class="md-raised md-primary" style="margin-top: 40px">Отмена</md-button>
    </div>

  </div>
</template>

<script>
  import {checkSuccess} from "../../utils/apiUtil";
  import {postMessage} from "../../api/message-controller";

  import {mapActions} from 'vuex'
  import {postMessageTypeChange} from "../../api/template-controller";

  export default {
    name: 'MessageForm',
    props: {
      action: Object,
      template: Object,
      selectedSignal: {
        type: Object,
        required: false
      },
      signalExercise: {
        type:Boolean,
        default: () => {
          return false
        }
      }
    },
    data() {
      return {
        loading: false,
        message: {header: this.template.header, content: this.template.content, footer: this.template.footer, name: this.template.name, exercise: this.exercise},
        exercise: this.signalExercise,
      }
    },
    methods: {
      ...mapActions(['showInfoAlert']),
      async changeVersionIfPossible() {
        console.log('changeVersionIfPossible', this.exercise)
        if (!this.selectedSignal) return;
        if (!this.selectedSignal.exercise) return;
        this.loading = true
        try {
          let data = await postMessageTypeChange(this.selectedSignal.id, this.template.id, this.exercise)
          if (data.success) {
            let body = data.payload
            this.message = {header: body.header, content: body.content, footer: body.footer, name: this.template.name, exercise: this.exercise}
          }
        } finally {
          this.loading = false
        }
      },
      alertx(x) {
        this.showInfoAlert(x)
        this.$emit('closeMessageForm')
      },
      cancelMessage() {
        this.$emit('messageCanceled')
      },
      async sendMessage() {
        console.log('test send')
        let message = this.message//{exercise: this.exercise, header: this.header, footer: this.footer, content: this.text}
        let result = await postMessage(message)
        if (checkSuccess(result)) {
          this.alertx("Сообщение отправлено")
          this.$emit('messageSent', this.message)
        } else {
          this.alertx('Ошибка отправки сообщения')
          this.$emit('messageFailed', this.message)
        }
      },
    },

  }
</script>

<style scoped>
  .minvw {
    min-width: 50vw;
  }


  .md-field.md-has-textarea {
    padding-top: 0;
    margin-bottom: 0;
    margin-top: 2px;
  }
</style>