<template>
  <div class="contacts-container" style="display: flex; margin: auto auto 0 auto; align-items: center; height: 100%; flex-wrap: wrap; padding: 5px; gap: 20px 10px;">
    <span class="contacts" style="padding-right: 10px">контакты</span>
    <span class="contacts"><a href="mailto:andrey.savlov@constant.obninsk.ru" >andrey.savlov@constant.obninsk.ru</a></span>
  </div>
</template>

<script>
  export default {
    name: 'bottom-footer'
  }
</script>

<style scoped>
  @media screen and (max-width: 400px) {
    span.contacts {
      flex-grow: 1; text-align: center
    }
  }
  @media screen and (min-width: 400px) {
    .contacts-container {
     justify-content: center;
    }
  }
</style>