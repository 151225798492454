<template>
  <div>
    <div style="display: flex; justify-content: space-between; flex-wrap: wrap">
      <div>
        <md-field style="width: 400px; max-width: 70vw" >
          <label for="role">{{selectedMessageType ? 'Тип сообщений' : 'Тип сообщений'}}</label>
          <md-select v-model="selectedMessageType" name="value" id="message_type">
            <md-option :key="'last'" value="last">Все последние (72 часа)</md-option>
            <md-option  v-for="(name, value) in types" :key="value" :value="value">{{name}} (72 часа)</md-option>
            <md-option :key="'dateFilter'" value="dateFilter">Все за выбранную дату</md-option>
          </md-select>
        </md-field>
      </div>
      <!--<div>Сообщения</div>-->
      <!--<div>Дата  <input-->
      <!--v-model="selectedDate"-->
      <!--type="datetime-local"-->
      <!--id="meeting-time"-->
      <!--name="meeting-time"-->
      <!--min="2024-01-01T00:00" /></div>-->
      <div style="align-items: flex-start; padding-top: 14px" v-show="selectedMessageType === 'dateFilter'">
        <input  v-model="filterDate"
                type="date"
                id="alert-time"
                name="alert-time"
                min="2024-01-01"
                :max="oldMessageDate()"
                required
                @change="getMessagesByDate"
        />
        <!--<input type="time" v-model="filterTime" style="margin-left: 20px" required @change="getNewAndSetInterval">-->
        <span style="margin-left: 10px"> ВСВ </span>
      </div>
    </div>
    <div class="message-container">
      <md-dialog :md-active.sync="showViewMessageForm"
                 :before-close="() => showViewMessageForm = false"
      >
        <md-dialog-content style="padding: 10px">
          <!--<md-dialog-actions>-->
          <!--<md-button class="md-primary" @click="showViewMessageForm = false">Закрыть</md-button>-->
          <!--</md-dialog-actions>-->
          <view-message-form :message="selectedMessage" @closeViewMessageForm="handleCloseViewMessageForm" :confirmEnabled="true"/>
        </md-dialog-content>
      </md-dialog>

      <table>
        <thead>
        <tr>
          <th style="width: 40px"></th>
          <th>Дата</th>
          <th>Время</th>
          <th>Отправитель</th>
          <th>Заголовок</th>
          <th style="width: 10px"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="message in filteredMessages" :key="message.id" :class="{'selected': selectedMessage && message.id === selectedMessage.id, 'viewed' : message.viewed && !(selectedMessage && message.id === selectedMessage.id)}" @click="openMessage(message)">
          <!--<td style="width: 40px; "><span style="font-weight: 600" :style="{'color': message.type.includes('input') ? 'green':'blue'}" v-html="getArrowSign(message.type)" ></span><span style="padding-left: 4px">{{getConfirmationSign(message.type)}}</span></td>-->
          <td style="width: 40px; "><img :src="getArrowPic(message.type)" alt="вх" height="28" width="20" style="margin: -2px 0 0 0"><span style="padding-left: 4px; font-weight: 600; line-height: 20px;">{{getConfirmationSign(message.type)}}</span></td>
          <td>{{getDateH(message)}}</td>
          <td>{{getTimeH(message)}}</td>
          <td>{{message.sender}}</td>
          <td>{{message.address}} </td>
        </tr>
        <tr v-if="!filteredMessages || !filteredMessages.length" >
          <td colspan="5">Нет сообщений</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  // import logger from "../utils/logger"
  import {
    getActualMessages,
    getActualMessagesByDate,
    MESSAGE_TYPE,
    MESSAGE_TYPE_TITLES,
    MESSAGE_COLUMNS,
    markMessageViewed
  } from "../api/message-controller";
  // import {getUtcDateFromInput} from "../utils/time";
  // import {getFormattedDate} from "../utils/timezone";
  import {getTime, getDate, dateInputToDateString, reformatDateInput} from "../utils/time"
  import ViewMessageForm from "./base/ViewMessageForm"
  import {SoundCall} from "../utils/sound/soundCall";
  import {mapGetters} from "vuex";
  // let signalInterval = null

  const sound = new SoundCall()

  let messageInterval = null
  const MESSAGE_INTERVAL_MS = 15000

  export default {
    name: 'messages',
    components: {
      ViewMessageForm
    },
    data() {
      return {
        types: MESSAGE_TYPE_TITLES,
        columns: MESSAGE_COLUMNS,
        selectedMessageType: 'last',
        // selectedDate: getUtcDateFromInput(getFormattedDate('UTC', new Date()).replaceAll(", ", "T")),
        // filterDate: new Date().toISOString().split('T')[0],
        filterDate: this.oldMessageDate(),
        filterTime: '00:00',
        messages: [],
        filteredMessages: [],
        selectedMessage: null,
        showViewMessageForm: false,
        lastMessageDate: null,
        // inMessages: [],
        // inConfirmations: [],
        // outMessages: [],
        // outConfirmations: []

      }
    },
    watch: {
      selectedMessageType(newVal) {
        if (newVal === 'dateFilter') {
          this.getMessagesByDate()
        } else {
          this.updateMessages(newVal)
        }
      }
    },
    computed: {
      ...mapGetters(['getSoundOffStatus']),

    },
    methods: {
      oldMessageDate() {
        let date = new Date()
        date.setDate(date.getDate() - 3)
        let dateString = reformatDateInput(getDate(date))
        console.log('dateString', dateString)
        return dateString
      },
      getArrowSign(type) {
        // if (type.includes('output')) return '&larr; '
        // if (type.includes('input')) return '&rarr; '
        if (type.includes('output')) return '&#11164;'
        if (type.includes('input')) return '&#11166'
        return ''
      },
      getArrowPic(type) {
        // if (type.includes('output')) return '&larr; '
        // if (type.includes('input')) return '&rarr; '
        if (type.includes('input')) return '/pics/left.png'
        if (type.includes('output')) return '/pics/right.png'
        return ''
      },
      getConfirmationSign(type) {
        return type.includes('confirmation') ? 'П' : ''
      },
      openMessage(message) {
        this.selectedMessage = message;
        this.showViewMessageForm = true
        markMessageViewed(message.id)
          .then(() => {
            message.viewed = true
          })
      },
      getDateH(message) {
        if (message.type === MESSAGE_TYPE.INPUT_MESSAGE || message.type === MESSAGE_TYPE.INPUT_CONFIRMATION) {
          return getDate(message.received)
        } else {
          return getDate(message.sent)
        }
      },
      getTimeH(message) {
        if (message.type === MESSAGE_TYPE.INPUT_MESSAGE || message.type === MESSAGE_TYPE.INPUT_CONFIRMATION) {
          return getTime(message.received)
        } else {
          return getTime(message.sent)
        }
      },
      // async getActualMessages(type) {
      //   let date = this.filterDate ? new Date(dateInputToDateString(this.filterDate + ' ' + this.filterTime)) : null
      //   let results = await getActualMessages(type, date)
      //   let messages = results ? results : []
      //   // this.updateLastDate(messages)
      //   logger('this.getSoundOffStatus2', this.getSoundOffStatus)
      //   // if (!this.getSoundOffStatus) sound.checkAndPlayMessage(this.messages)
      //   // return results.payload ? results.payload : []
      //   // return results ? results : []
      // },
      updateLastDate(messages) { //@deprecated
        let lastMessage = messages && messages.length ? messages[messages.length - 1] : null
        if (!lastMessage) return;
        switch (lastMessage.type) {
          case MESSAGE_TYPE.INPUT_MESSAGE: this.lastMessageDate = lastMessage.received; break
          case MESSAGE_TYPE.OUTPUT_MESSAGE: this.lastMessageDate = lastMessage.sent; break;
          default:
            this.lastMessageDate = null
        }
      },
      async getMessagesByDate() {
        let startDate = this.filterDate ? new Date(dateInputToDateString(this.filterDate + ' ' + '00:00')) : null
        let endDate = new Date(startDate)
        endDate.setDate(endDate.getDate()+1)
        let messagesByDate = await getActualMessagesByDate(startDate, endDate)
        this.filteredMessages = messagesByDate.reverse()
      },
      async updateMessages() {
        let startDate = new Date()
        startDate.setDate(startDate.getDate() - 3)

        let messages = await getActualMessages(startDate)
        if (!this.getSoundOffStatus) {
          console.log('play message', new Date())
          sound.checkAndPlayMessage(messages)
        }

        if (this.selectedMessageType !== 'dateFilter') {
          if (this.selectedMessageType === 'last') {
            this.filteredMessages = messages.reverse()
          } else {
            this.filteredMessages = messages.filter(m => m.type === this.selectedMessageType).reverse()
          }
        }

        // let inMessages = await this.getActualMessages(MESSAGE_TYPE.INPUT_MESSAGE, date)
        // let inConfirmations = await this.getActualMessages(MESSAGE_TYPE.INPUT_CONFIRMATION, date)
        // let outMessages = await this.getActualMessages(MESSAGE_TYPE.OUTPUT_MESSAGE, date)
        // let outConfirmations = await this.getActualMessages(MESSAGE_TYPE.OUTPUT_CONFIRMATION, date)
        // switch (type) {
        //   case MESSAGE_TYPE.INPUT_MESSAGE:
        //     this.messages = inMessages;
        //     break;
        //   case MESSAGE_TYPE.INPUT_CONFIRMATION:
        //     this.messages = inConfirmations
        //     break;
        //   case MESSAGE_TYPE.OUTPUT_MESSAGE:
        //     this.messages = outMessages;
        //     break;
        //   case MESSAGE_TYPE.OUTPUT_CONFIRMATION:
        //     this.messages = outConfirmations;
        //     break;
        //   default:
        //     this.messages = []
        // }
        // this.updateLastDate(this.messages)
        // if (inMessages.some(m => {
        //   console.log(!m.viewed)
        //   return !m.viewed
        // })) {
        //   if (!this.getSoundOffStatus) {
        //     console.log('play message', new Date())
        //     sound.checkAndPlayMessage(inMessages)
        //   }
        // }
      },
      getTitle(key) {
        return MESSAGE_TYPE_TITLES[key]
      },
      handleCloseViewMessageForm() {
        this.showViewMessageForm = false
      },
      async getNewAndSetInterval() {
        // await this.updateMessages()
        let that = this
        if (messageInterval) {
          clearInterval(messageInterval)
        }
        that.updateMessages()
        messageInterval = setInterval(() => {
          that.updateMessages()
        }, MESSAGE_INTERVAL_MS)
      },
    },
    async mounted() {
      this.getNewAndSetInterval()
    },
    destroyed() {
      if (messageInterval) {
        clearInterval(messageInterval)
      }
    }
  }
</script>

<style scoped>
  @media (min-width: 599px){

    .message-container {
      max-height: calc(56vh - 40px);
      overflow-y: auto;
      min-height: 300px;
    }

    table tbody {
      display: block;
      max-height: calc(65vh - 200px);
      overflow-y: scroll;
    }

    table thead, table tfoot, table tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    td {
      border: 1px solid rgba(0,0,0,0.5);
      line-height: 20px;
      font-size: 14px;
      padding: 2px;
    }

  }

  @media (max-width: 600px){
    th {
      padding: 2px;
    }
    td {
      padding: 4px;
      border: 1px solid rgba(0,0,0,0.5);
      line-height: 24px;
      font-size: 15px;
    }
  }

  tr.selected {
    background-color: #9acfea;
  }

  tr.viewed {
    background-color: lightgray;
  }

</style>
