<template>
  <div style="display: flex; text-align: center; justify-content: center; font-size: 24px">
    <br>
    Вы зашли под пользователем с ролью {{userRole}}
    <br>
    <br>
    К сожалению, вы не можете просматривать страницы для пользователей с ролью {{requiredRole}}
    <br>
    <br>
    Требуется выйти и зайти с указанной ролью {{requiredRole}}
  </div>
</template>
<script>
  import {getItem} from "../utils/persistanceStorage";

  export default {
    name: 'wrongRole',
    computed: {
      requiredRole() {
        // if (getItem('auth').roles.includes('master')) roles.push('Мастер администратор')
        // if (getItem('auth').roles.includes('administrator')) roles.push('Администратор')
        // if (getItem('auth').roles.includes('oceanographer')) roles.push('Океанограф')
        // if (getItem('auth').roles.includes('seismologist')) roles.push('Сейсмолог')
        // if (getItem('auth').roles.includes('expert')) roles.push('Эксперт')
        return 'Эксперт'
      },
      userRole() {
        let roles = []
        if (getItem('auth').roles.length) {
          if (getItem('auth').roles.includes('master')) roles.push('Мастер администратор')
          if (getItem('auth').roles.includes('administrator')) roles.push('Администратор')
          if (getItem('auth').roles.includes('oceanographer')) roles.push('Океанограф')
          if (getItem('auth').roles.includes('seismologist')) roles.push('Сейсмолог')
          if (getItem('auth').roles.includes('expert')) roles.push('Эксперт')
        } else {
          roles.push('Неавторизованный пользователь')
        }
        return roles.join(', ')
      }
    }
  }
</script>