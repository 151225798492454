import { render, staticRenderFns } from "./TemplateMessageForm.vue?vue&type=template&id=724e48e0&scoped=true"
import script from "./TemplateMessageForm.vue?vue&type=script&lang=js"
export * from "./TemplateMessageForm.vue?vue&type=script&lang=js"
import style0 from "./TemplateMessageForm.vue?vue&type=style&index=0&id=724e48e0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "724e48e0",
  null
  
)

export default component.exports