<template>
  <div style="width: 100%; text-align: center">
    <splitpanes class="default-theme" :push-other-panes="false" horizontal>
      <pane>
        <div style="margin: 0 auto; display: block; justify-items: center; justify-content: center; width: 100%; padding: 0 auto; overflow-y: auto" >
          <div style="display: flex; width: 100%; justify-content: space-evenly; flex-wrap: wrap; gap: 20px; padding: 2px 15px">
            <a href="#" @click="showSignalCreateForm = !showSignalCreateForm" class="action-link">
              <img src="/pics/alert-add.png" class="action-icon"/>
              Добавить сигнал</a>
            <a href="#" @click="closeSignal" class="action-link">
              <img src="/pics/alert-delete.png" class="action-icon"/>
              Удалить сигнал</a>
            <a href="#" @click="showTemplateMessageForm = !showTemplateMessageForm" class="action-link">
              <img src="/pics/mail-arrow_right.png" class="action-icon"/>
              Отправить телеграмму</a>
            <!--<a href="#"  @click="showResults"  class="action-link" :class="{'disabled': !selectedSignal || !selectedSignal.hasResults}">-->
            <a href="#"  @click="showResults"  class="action-link" :class="{'disabled': !isCalculationReady}">
              <img src="/pics/stats.png" class="action-icon" />
              Результаты расчетов
            </a>
            <a href="#" class="action-link disabled">
              <img src="/pics/help2.png" class="action-icon"/>
              Режим тренажера</a>
            <a href="#"  class="action-link" @click="makeMapShot">
              <img src="/pics/picture-arrow_down.png" class="action-icon"/>
              Снимок карты</a>

            <md-dialog :md-active.sync="showSignalCreateForm"
                       :before-close="() => showSignalCreateForm = false"
            >
              <md-dialog-content style="padding-top: 5px; padding-bottom: 5px">
                <md-dialog-actions>
                  <md-button class="md-primary" @click="showSignalCreateForm = false">Закрыть</md-button>
                </md-dialog-actions>
                <signal-form  @closeSignalForm="handleCloseSignalform"/>
              </md-dialog-content>
            </md-dialog>

            <md-dialog :md-active.sync="showDoingDialog"
                       :before-close="() => showDoingDialog = false"
            >
              <md-dialog-title>{{selectedDoing? selectedDoing.name : ''}}</md-dialog-title>
              <md-dialog-content style="padding-top: 5px; padding-bottom: 5px">
                <div>{{selectedDoing? selectedDoing.description : ''}}</div>
              </md-dialog-content>
              <md-dialog-actions>
                <md-button class="md-raised md-primary"  @click="doAct(selectedDoing, selectedDoing.id, true)">Продолжить</md-button>
                <md-button class="md-raised md-primary"  @click="doAct(selectedDoing, selectedDoing.id, false)">Пропустить</md-button>
              </md-dialog-actions>
            </md-dialog>

            <md-dialog :md-active.sync="showMessageForm" >
              <md-dialog-content style="padding: 20px; min-width: 260px">
                <message-form :action="selectedAction" @closeMessageForm="handleCloseMessageform" :template="selectedTemplate" @messageCanceled="handleMessageCanceled" @messageFailed="handleMessageFailed" @messageSent="handleMessageSent" :signal-exercise.sync="selectedActionExercise" :selected-signal.sync="selectedSignal"/>
              </md-dialog-content>
            </md-dialog>

            <md-dialog :md-active.sync="showTemplateMessageForm" >
              <md-dialog-content style="padding: 20px; min-width: 260px">
                <div style="text-align: center"><h3 style="margin-top: 0; margin-bottom: 0">Отправка сообщения <span v-if="selectedSignal">по сигналу</span></h3></div>
                <template-message-form role="oceanographer" @closeTemplateMessageForm="handleCloseTemplateMessageform" :signal="selectedSignal"/>
              </md-dialog-content>
            </md-dialog>

            <md-dialog :md-active.sync="showResultForm" >
              <md-dialog-content style="padding: 10px">
                <md-dialog-actions>
                  <md-button class="md-primary" @click="printCalcResults()">печать</md-button>
                  <md-button class="md-primary" @click="showResultForm = false">Закрыть</md-button>
                </md-dialog-actions>
                <calc-results-form  :signal="selectedSignal" :key="selectedSignalId"/>
              </md-dialog-content>
            </md-dialog>
          </div>
          <div style="width: 100%; overflow-x: auto">
            <!--{{signals}}-->

            <table id="signals-table" class=" table-striped table-bordered  table-sm" cellspacing="0" width="100%" style="height: 2vh">
              <thead>
              <tr>
                <th class="is_exersise" ></th>
                <th class="is_calc_ready" ></th>
                <th class="th-sm " >Дата
                </th>
                <th class="th-sm " >Время
                </th>
                <th class="th-sm " >Магнитуда
                </th>
                <th class="th-sm " >Широта
                </th>
                <th class="th-sm ">Долгота
                </th>
                <th class="th-sm " >Глубина гипоцентра
                </th>
                <th class="th-sm " >Зона
                </th>
                <th class="th-sm w120">Оценка опасности
                </th>
                <th class="scroll_column"></th>
              </tr>
              </thead>
              <tbody style="height: 200px">
              <tr v-for="signal in signals" :key="signal.id" @click="showAction(signal)" :class="{'selected-signal': signal.id === selectedSignalId}">
                <td class="is_exersise">
                  <div style="border-radius: 50%; width:18px; height: 18px; background-color: rgba(255,255,255,1); display: flex; justify-items: center; align-items: center" >
                    <div style="border-radius: 50%; width:14px; height: 14px; margin: 2px" :style="{'background-color': signal.exercise ? 'green' : 'red' }" :title="signal.exercise ? 'Учебная' : 'Настоящая'" data-toggle="tooltip"></div>
                  </div>
                </td>
                <td class="is_calc_ready">
                  <div><img v-if="signal.hasResults" src="/res/download.png" style="width: 20px; height: 20px; margin: 0"/></div>
                </td>
                <td style="min-width: 100px" class="w150">{{getDateH(signal.earthquakeDate)}}</td>
                <td style="min-width: 100px" class="w150">{{getTimeH(signal.earthquakeDate)}}</td>
                <td class="w150">{{signal.magnitude}}</td>
                <td class="w150">{{signal.latitude}}</td>
                <td class="w150">{{signal.longitude}}</td>
                <td class="w150">{{signal.depth || signal.depth === 0 ? signal.depth : '-'}}</td>
                <td class="w150">{{signal.zone}}</td>
                <td class="w120">{{checkTsunamigenic(signal.tsunamigenic)}}</td>
                <!--<td>-</td>-->
                <!--<td>{{signal.exercise ? 'да' : 'нет'}}</td>-->

              </tr>
              </tbody>
              <!--<tfoot>-->
              <!--<tr v-if="signals.length">-->
              <!--<th class="is_exersise"></th>-->
              <!--<th>Дата-->
              <!--</th>-->
              <!--<th>Время-->
              <!--</th>-->
              <!--<th>Магнитуда-->
              <!--</th>-->
              <!--<th>Широта-->
              <!--</th>-->
              <!--<th>Долгота-->
              <!--</th>-->
              <!--<th>Глубина гипоцентра-->
              <!--</th>-->
              <!--<th>Зона-->
              <!--</th>-->
              <!--<th class="w120">Оценка опасности-->
              <!--</th>-->
              <!--<th class="scroll_column"></th>-->
              <!--</tr>-->
              <!--</tfoot>-->
            </table>
          </div>
        </div>
      </pane>
      <pane>
        <splitpanes class="default-theme" :horizontal="isWide">
          <pane :min-size="10" size="30" style="padding-right: 4px; padding-left: 4px">
            <div class="basic-pane" style="display: flex; flex-wrap: wrap; ;" :style="{'min-height': showActions ?  '58vh' : '20px'}" >
              <div style="width: 100%" class="text-left"  >
                <div class="text-center " >Регламент {{ actionsLoading ? '(загрузка)' : ''}}</div>
                <div v-show="showActions" class="action-container" ref="actions-ref" >
                  <div  v-for="doing in action" :key="doing.id" >
                    <div @click="toggleParentExpand(doing.id)" class="doing-level1" :class="{'doing-parent': !!doing.childActions, 'doing-child': !doing.childActions && !doing.done && !doing.skipped, 'doing-done': doing.done, 'doing-skipped': doing.skipped}">
                      <div class="action" @click="act(doing,  doing.id, doing.id + ' ' + doing.description, doing.childActions)">
                        <!--{{doing.id}}.-->
                        <!--<md-icon md-src="/vuematerial/assets/icon-add.svg" />-->
                        <span class="act-done" v-show="doing.done" :title="doing.done" data-toggle="tooltip">&#10003;</span>
                        <span class="act-skipped" v-show="doing.skipped"  :title="doing.skipped" data-toggle="tooltip">&#10060;</span>
                        {{doing.name}}
                      </div>
                    </div>
                    <div style="padding-left: 10px">
                      <div v-show="expandedParents.includes(doing.id)" v-for="doing2 in doing.childActions" :key="doing2.id + '_2'" class="doing-child "
                           :style="{'background-color': doing2.done ? 'lightgreen' : (doing2.skipped) ? '#ffcccb' : '',
                         'box-shadow': doing2.done ? '10px 5px 5px green' : (doing2.skipped) ? '10px 5px 5px #bb99a9' : '10px 5px 5px gray',
                         'border': doing2.done ? '1px solid green' : (doing2.skipped) ? '1px solid #bb99a9' : '1px solid grey'}">
                        <div class="action doing-level2" @click="act(doing2,  doing2.id, doing2.id + ' ' + doing2.description, doing2.childActions); $event.preventDefault()" :class="{'doing-level2-parent': !!doing2.childActions}" >
                          <!--{{doing2.id}}.-->
                          <span class="act-done" v-show="doing2.done" :title="doing2.done" data-toggle="tooltip" v-if="!doing2.childActions">&#10003;</span>
                          <span class="act-skipped" v-show="doing2.skipped" :title="doing2.skipped" data-toggle="tooltip" v-if="!doing2.childActions">&#10060;</span>
                          {{doing2.name}}
                        </div>
                        <div v-for="doing3 in doing2.childActions" :key="doing3.id + '_3'" style=" margin-top: 10px; margin-bottom: 5px;  margin-left: 10px; padding-left: 10px"  :style="{'background-color': doing3.done ? 'lightgreen' : '', 'border' : doing3.childActions ? '' : '1px solid grey'}">
                          <div class="action" style="" @click="act(doing3,  doing3.id, doing3.id + ' ' + doing3.description, doing3.childActions)"  >
                            <!--{{doing3.id}}.-->
                            <span class="act-done" v-show="doing3.done" :title="doing3.done" data-toggle="tooltip">&#10003;</span>
                            <span class="act-skipped" v-show="doing3.skipped" :title="doing3.skipped" data-toggle="tooltip">&#10060;</span>
                            {{doing3.name}}</div>
                          <div v-for="doing4 in doing3.childActions" :key="doing4.id + '_3'" style="border: 1px solid grey; margin-top: 10px; margin-bottom: 5px;  margin-left: 10px; padding-left: 10px"  :class="{'doing-done': doing4.done, 'doing-skipped': doing4.skipped }"  >
                            <div class="action" style="" @click="act(doing4,  doing4.id, doing4.id + ' ' + doing4.description, doing4.childActions)"  >
                              <!--{{doing3.id}}.-->
                              <span class="act-done" v-show="doing4.done" :title="doing4.done" data-toggle="tooltip">&#10003;</span>
                              <span class="act-skipped" v-show="doing4.skipped" :title="doing4.skipped" data-toggle="tooltip">&#10060;</span>
                              {{doing4.name}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </pane>
          <pane :min-size="35" size="70">
            <div class="basic-pane">
              <div style="margin-bottom: 5px"><span class="active-menu-1" :class="{'modeMenuSelected' : mode === 'map'}" @click="mode = 'map'">Карта</span> / <span class="active-menu-1" @click="mode='messages'" :class="{'modeMenuSelected' : mode === 'messages'}">Сообщения</span></div>
              <div style="padding: 0 10px" v-show="mode === 'messages'"><messages /></div>
              <div style="padding: 0 10px" v-if="mode === 'map'" ><ocean-map :signal="selectedSignal"/></div>
              <!--<div style="padding: 10px":style="{'opacity': mode === 'map' ? '1' : '0'}"><ocean-map :signal="selectedSignal"/></div>-->
              <!--<div style="padding: 10px" :style="{'display': mode === 'map' ? 'block' : 'none'}"><ocean-map /></div>-->
            </div>
          </pane>
        </splitpanes>
      </pane>
    </splitpanes>
  </div>

</template>

<script>
  import {closeSignal, getSignals} from "../api/signal-controller";
  import {doAction, getAction} from "../api/action-controller";
  import logger from "../utils/logger";
  import {getTime, getDate} from "../utils/time"
  import {checkSuccess} from "../utils/apiUtil";
  import SignalForm from "../components/base/SignalFormDialog"
  import MessageForm from "../components/base/MessageForm"
  import TemplateMessageForm from "../components/base/TemplateMessageForm"
  import CalcResultsForm from "../components/base/CalcResults"
  import Messages from "../components/Messages";
  import OceanMap from "../components/OceanMap"
  // import OceanMap from "../components/OceanMapMike"
  import {getTemplateMessages} from "../api/template-controller";
  import { Splitpanes, Pane } from 'splitpanes'
  import 'splitpanes/dist/splitpanes.css'
  import html2canvas from 'html2canvas';

  import {mapGetters, mapActions} from "vuex";


  import {SoundCall} from "../utils/sound/soundCall";
  const sound = new SoundCall()

  export default {
    name: 'Oceanographer',
    components: {
      Messages,
      SignalForm,
      MessageForm,
      TemplateMessageForm,
      CalcResultsForm,
      Splitpanes, Pane,
      OceanMap
    },
    data() {
      return {
        mode: 'map',
        actionsLoading: false,
        expandedParents: [],
        secondShow: false,
        signals: [],
        action: [],
        selectedSignalId: null,
        selectedSignal: {},
        selectedAction: null,
        selectedActionExercise: null,
        selectedTemplate: {},
        showSignalCreateForm: false,
        showMessageForm: false,
        showTemplateMessageForm: false,
        showResultForm: false,
        showActions: true,
        messageTemplates: [],
        // commonTemplates: [],
        resizeDate: null,

        signalUpdateInterval: null,
        selectedDoing: null,
        showDoingDialog: false
      }
    },
    computed: {
      ...mapGetters(['getSoundOffStatus']),
      isCalculationReady() {

        if (!this.selectedSignalId) {
          // console.log('check x', this.selectedSignalId, false)
          return false;
        } else {
          let selectedSignal = this.signals.filter(signal => signal.id === this.selectedSignalId)
          if (!selectedSignal || !selectedSignal.length) return false;
          // console.log('check x', this.selectedSignalId, !!selectedSignal[0])
          return !!selectedSignal[0] && selectedSignal[0].hasResults
        }
      },
      isWide() {
        if (this.resizeDate) {
          console.log('resize', this.resizeDate, window.outerWidth)

          if (window.outerWidth < 799) {
            return true
          } else {
            return false
          }
        }
        return window.outerWidth < 799
      }
    },
    methods: {
      ...mapActions(['showInfoAlert', 'loadGauges']),
      async makeMapShot() {
        let el = document.getElementById('map')

        console.log('el', el)
        //TODO make map screenshot
        // const response = await doScreenshot({format:'png'});
        // let canvas = document.getElementsByClassName('ol-layer')[0]
        // var image = canvas.toDataURL("image/png");
        // const element = document.createElement('a');
        // element.setAttribute('href', image);
        // element.setAttribute('download', 'карта-' + (new Date().toUTCString() + '.png'));
        // element.style.display = 'none';
        // document.body.appendChild(element);
        // element.click();
        // document.body.removeChild(element);


        html2canvas(el).then(canvas => {
          // vc.saveAs(canvas.toDataURL(), filename);
          var image = canvas.toDataURL("image/png");//.replace("image/png", "image/octet-stream");  // here is the most important part because if you dont replace you will get a DOM 18 exception.

          var a = document.createElement('a');
          a.href = image;
          a.download = 'карта_' + new Date().toISOString().replace('T',' ').split('.')[0] + '.png';
          document.body.appendChild(a);
          a.click();

          // window.location.href=image; // it will save locally
        }).catch(() => {
          this.showInfoAlert("Ошибка при сохранении снимка карты")
        });
      },
      printCalcResults() {
        console.log('starting print')
        const tableContent = document.getElementById("calc_results_to_print");
        const printWindow = window.open('', '', 'height=400,width=600');
        const printContent = printWindow.document.createElement('div');
        var cssHead = '<style>.colored-bar { display: none;}</style>'
        printWindow.document.title = "расчет_" + this.selectedSignal.earthquakeDate
        printContent.style.fontFamily = 'Arial';
        printContent.style.fontSize = '12px';

        // Устанавливаем текст textarea в элемент <div>
        // printContent.innerHTML = tableContent.value.replaceAll("\n", '<br>');
        printContent.innerHTML = tableContent.innerHTML
        printContent.innerHTML += cssHead

        // Добавляем элемент <div> в тело документа нового окнаd
        printWindow.document.body.appendChild(printContent);

        // Печатаем содержимое нового окна
        printWindow.print();

        // Закрываем новое окно
        printWindow.close();
      },
      toggleParentExpand(id) {
        if (this.expandedParents.includes(id)) {
          let index = this.expandedParents.indexOf(id)
          this.expandedParents.splice(index, 1)
        } else {
          this.expandedParents.push(id)
        }
      },
      async handleMessageSent() {
        console.log('handleMessageSent')
        if (this.selectedAction.messageTemplateId === this.selectedTemplate.id) {
          let result = await doAction(this.selectedSignalId, this.selectedAction.id, true)
          if (checkSuccess(result)) {
            this.selectedAction.done = new Date()

            this.$forceUpdate();
          }
        }
      },
      handleMessageFailed() {
        console.log('Ошибка отправки сообщния')
      },
      handleMessageCanceled() {
        console.log('Отмена отправки сообщения')
        this.handleCloseMessageform()
      },
      checkTsunamigenic(isTsuanigenic) {
        if (isTsuanigenic === true) {
          return 'цунамигенное'
        } else if (isTsuanigenic === false) {
          return "нецунамигенное"
        } else {
          return ''
        }
      },
      async init() {
        // let messageTemplates = await getTemplateMessages()
        // this.commonTemplates = messageTemplates.payload ? messageTemplates.payload : []
        // logger('available messages', this.commonTemplates)

        this.updateSignals()


        let that = this
        if (this.signalUpdateInterval) {
          clearInterval(this.signalUpdateInterval)
        }

        this.updateSignals()
        this.signalUpdateInterval = setInterval(async () => {
          await that.updateSignals()
        }, 25000)
      },
      async updateSignals() {

        // this.signals = []
        let result = await getSignals()
        logger('signals', result)
        this.signals = result.payload ? result.payload : []
        logger('this.getSoundOffStatus', this.getSoundOffStatus)
        if (!this.getSoundOffStatus) {
          logger('play signal', new Date())
          sound.checkAndPlaySignals(this.signals);
        }
        if (this.selectedSignal) {
          if (!this.signals.length || !this.signals.some(s => s.id === this.selectedSignal.id)) {
            this.selectedSignal = null
            this.selectedSignalId = null
            this.action = []
          }
        }
      },
      getDateH(date) {
        return getDate(date)
      },
      getTimeH(date) {
        return getTime(date)
      },
      async showAction(signal) {
        this.actionsLoading = true
        this.selectedSignalId = signal.id
        this.selectedSignal = signal
        this.action = null
        try {
          let result = await getAction(this.selectedSignalId)

          // logger(result)

          let messageTemplates = await getTemplateMessages(this.selectedSignalId)
          this.messageTemplates = messageTemplates.payload ? messageTemplates.payload : []

          this.action = result.payload
          this.showActions = true

        } finally {
          this.actionsLoading = false
        }

      },
      async act(doing, id, description, hasChildren) {
        if (hasChildren) return;
        // let doing = this.action.find(a => a.id === id && !a.childActions)
        if (doing.childActions) return;
        console.debug('act')
        this.selectedDoing = doing
        this.showDoingDialog = true
        // let doneFlag = await confirm(description, 'Сделано', 'Пропустить')
        //
        // this.doAct(doing, id, doneFlag)
      },
      async doAct(doing, actionId, doneFlag) {
        this.showDoingDialog = false
        this.selectedAction = doing
        this.selectedActionExercise = this.selectedSignal.exercise

        if (doing.messageTemplateId && doneFlag) {
          console.log('template', this.messageTemplates)
          if (this.messageTemplates && this.messageTemplates.length) {
            this.selectedTemplate = this.messageTemplates.find(mt => mt.id === doing.messageTemplateId)
          }
          this.showMessageForm = true
        } else {
          console.log(1/0)
          let result = await doAction(this.selectedSignalId, actionId, doneFlag)
          if (checkSuccess(result)) {
            if (doneFlag) {
              doing.done = true
              if (this.selectedAction.name === 'Завершить регламент') {
                this.closeSignal()
              }
            } else {
              doing.skipped = true
            }
            this.$forceUpdate();
          }
        }
        this.showDoingDialog = false
        console.log('doing result', JSON.stringify(doing))
      },
      showResults() {
        // if (!this.selectedSignal || !this.selectedSignal.hasResults) return;
        if (!this.selectedSignal) return;
        this.showResultForm = true
      },
      async closeSignal() {
        if (!this.selectedSignal) return;
        let response = await closeSignal(this.selectedSignalId)
        if (response.code && response.code === 'SUCCESS') {
          this.showInfoAlert('Сигнал успешно удален')
          this.updateSignals()
        }
      },
      handleCloseSignalform() {
        this.showSignalCreateForm = false
        this.init()
      },
      handleCloseMessageform() {
        this.showMessageForm = false
        // this.init()
      },
      handleCloseTemplateMessageform() {
        this.showTemplateMessageForm = false
        // this.init()
      }
    },
    async mounted() {
      this.init()
      this.loadGauges()
      let that = this
      window.addEventListener('resize', function() {
        that.resizeDate = new Date()
      }, true);

    },
    destroyed() {
      if (this.signalUpdateInterval) {
        clearInterval(this.signalUpdateInterval)
      }
    }
  }
</script>

<style scoped>
  .basic-pane {
    width: 100%;
    /*border: 1px solid black;*/
    margin: 5px 10px 5px 5px;
    flex-grow: 9999;
    min-height: 58vh;
    padding: 5px
  }

  @media (min-width: 1000px){

    table tbody {
      display: block;
      overflow-y: scroll;
      max-height: 110px;
    }

    .action-container {
      max-height: 55vh;
      overflow-y: auto;
    }

    .message-container {
      max-height: calc(56vh - 20px);
      overflow-y: auto;
    }

    .scroll_column {
      width: 15px;
    }

    td {
      line-height: 20px;
      font-size: 16px;
    }

    div.action {
      line-height: 24px;
      font-size: 14px;
    }



  }

  @media (max-width: 400px) {
    .scroll_column {
      width: 0;
      padding: 0;
    }

    .splitpanes.default-theme {
      flex-direction: column !important;
    }


    table tbody {
      display: block;
      overflow-y: scroll;
    }
  }
  @media (max-width: 1000px){
    td.w150 {
      width: 150px;
      margin: 0;
      box-sizing: border-box;
    }
    th.w150 {
      padding: 4px;
      width: 148px;
      margin: 0;
      box-sizing: border-box;
    }
    td.w120, th.w120 {
      width: 170px;
      margin: 0;
      box-sizing: border-box;
    }


    td {
      line-height: 26px;
      font-size: 20px;
    }

    div.action {
      line-height: 26px;
      font-size: 20px;
    }

    .scroll_column {
      width: 15px;
    }
  }

  table tbody {
    display: block;
    overflow-y: scroll;
  }

  table thead, table tfoot, table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .is_exersise {
    width: 30px;
  }

  .is_calc_ready {
    width: 30px;
  }

  .selected-signal {
    background-color: rgba(200, 200, 200, 1) !important;
  }

  .splitpanes__splitter {
    cursor: col-resize;
  }

  .act-done {
    color:green; font-weight: 900; font-size: 20px
  }

  .act-skipped {
    color: red; font-weight: 400; font-size: 14px
  }

  #signals-table td {
    padding: 2px;
  }

  .modeMenuSelected {
    text-decoration: underline;
  }

  .action-icon {
    padding-right: 8px;
    margin: 0
  }
  .action-link {
    flex: 1 1;
    display: flex;
    font-size: 16px;
    align-items: center;
  }

  .action-link.disabled img {
    opacity: 0.3
  }

  .doing-level2-parent {
    border: 1px solid rgba(4,40,4,0.1);
    background-color: rgba(0, 0, 250, 0.2);
    box-shadow: 10px 5px 5px rgba(60, 60, 0, 1)
  }

  .doing-parent {
    border: 1px solid rgba(4,40,4,0.1);
    background-color: rgba(0, 0, 250, 0.2);
    box-shadow: 10px 5px 5px rgba(60, 60, 0, 1)
  }

  .doing-level1-child {
    background-color: lightgreen;
    border : 1px solid grey;
    box-shadow: 10px 5px 5px rgba(60, 60, 0, 1)

  }

  .doing-child {
    margin: 10px 5px 10px 10px;
    border : 1px solid grey;
    box-shadow: 10px 5px 5px rgba(0, 0, 0, 1)
  }

  .doing-level1 {
    margin: 10px;
    padding-left: 10px;
    padding-right: 5px;
    min-height: 30px;
  }

  .doing-done {
    background-color: lightgreen;
    border : 1px solid grey;
    box-shadow: 10px 5px 5px rgba(60, 60, 0, 1)
  }

  .doing-skipped {
    background-color: #ffcccb;
    border: 1px solid #bb99a9;
    box-shadow: 10px 5px 5px #bb99a9
  }


  .doing-level2 {
    padding-left: 10px; ;
  }

  .active-menu-1 {
    cursor: pointer
  }

  .active-menu-1:hover {
    text-decoration: underline;
  }
</style>
