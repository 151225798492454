<template>
  <div style="text-align: center">
    <md-dialog :md-active.sync="showViewMessageForm"
               :before-close="() => showViewMessageForm = false"
    >
      <md-dialog-content style="padding: 10px">
        <!--<md-dialog-actions>-->
        <!--<md-button class="md-primary" @click="showViewMessageForm = false">Закрыть</md-button>-->
        <!--</md-dialog-actions>-->
        <view-message-form :message="selectedMessage" @closeViewMessageForm="handleCloseViewMessageForm2"/>
      </md-dialog-content>
    </md-dialog>

    <div id="filter-headers" @click="showFilters = !showFilters" style="cursor: pointer;position: sticky">Выберите фильтры {{showFilters ? '(скрыть)' : '(показать)'}}</div>
    <div id="filter-containers" v-show="showFilters" style="display: flex; flex-direction: column; justify-content: center; align-items: center">
      <div style="display: flex; flex-wrap: wrap; align-items: baseline; text-align: center; width: 100%; justify-content: center">
        <div style="align-items: flex-start; padding-top: 14px">
          Выберите дату (ВСВ)
          <input  v-model="filterDate"
                  type="date"
                  id="alert-time"
                  name="alert-time"
                  min="2024-01-01"
                  required
          />
          <input type="time" v-model="filterTime" style="margin-left: 20px" required>
          <span style="margin-left: 10px"> (&#177; 24 часа)</span>
        </div>
      <div>
      </div>
      <md-button   @click="resetFilters" class="md-raised md-primary">
        Сбросить
      </md-button>
      <md-button   @click="refresh" class="md-raised md-primary">
        Поиск
      </md-button>
    </div>
    </div>
    <div style="text-align: center; width: 100%">
    <md-table v-model="filteredLogs" :loading="loading" md-sort="dateTime" md-sort-order="asc" md-card md-fixed-header><!-- setup fixed header on big screen-->
      <md-table-toolbar>
        <h1 class="md-title"  data-toggle="tooltip">Всего найдено записей: {{logs ? logs.length : 0}} </h1>

        <md-field md-clearable class="md-toolbar-section-end">
          <md-input placeholder="Поиск по описанию" v-model="search" @input="searchOnTable" />
        </md-field>
      </md-table-toolbar>


      <md-table-empty-state v-show="search"
                            md-label="Записей не найдено"
                            :md-description="`Не найдено записей для поискового запроса -  '${search}'.`">
      </md-table-empty-state>


      <md-table-row slot="md-table-row"  md-sort="dateTime" md-sort-order="asc" slot-scope="{ item }" @click="openMessage(item)">
        <!--<md-table-row slot="md-table-row" slot-scope="{ item }">-->
        <md-table-cell md-label="#" md-sort-by="id">{{ item.id}}</md-table-cell>
        <md-table-cell md-label="Дата" md-sort-by="received">{{ formatDate(item.received)}}</md-table-cell>
        <md-table-cell md-label="Отправитель" md-sort-by="sender">{{ item.sender }}</md-table-cell>
        <md-table-cell md-label="Адрес" md-sort-by="description">{{ item.address }}</md-table-cell>
      </md-table-row>
    </md-table>
    </div>
  </div>
</template>

<script>
  import {getUser, getUsers} from "../api/user-controller";
  import {dateInputToDateString, getDateTime} from "../utils/time";
  import {getSignals} from "../api/signal-controller";
  import {getActualMessages, getTriggeringMessages} from "../api/message-controller";
  import {checkSuccess} from "../utils/apiUtil";
  import {translate} from "../utils/translations";

  import ViewMessageForm from "../components/base/ViewMessageForm"

  import {mapActions} from 'vuex'

  const toLower = text => {
    return text.toString().toLowerCase()
  }

  const searchByName = (items, term) => {
    if (!items) return [];


    if (term) {
      return items.filter(item => toLower(item.content).includes(toLower(term)))
    }

    return items
  }

  export default {
    name: 'TriggeringMessages',
    components: {
      ViewMessageForm
    },
    data() {
      return {
        showViewMessageForm: false,
        showFilters: true,
        filterDate: null,
        filterTime: '00:00',
        selectedUser: null,
        selectedSignal: null,
        selectedMessage: null,
        users: [],
        signals: [],
        messages: [],
        pageSize: 100,
        loading: true,
        userId: null,//this.$route.params.userId,
        logs: [],
        user: null,
        search: '',
        filteredLogs: [],
        resizeDate: new Date()
      }
    },
    computed: {
      onBigScreen() {
        return false;//window.screen.availHeight > 800
        // return window.screen.availHeight > 800
      },
      pagesCount() {
        return (this.filteredLogs / this.pageSize)
      },
      tableSize() {
        let fullSizeScreen = window.screen.height
        let windowSize = window.screen.availHeight
        console.log('fu;;', fullSizeScreen, 'avait', windowSize, this.resizeDate)
        // if (windowSize < fullSizeScreen) {
        //   return `"100%" //calc(${fullSizeScreen}px - 60vh)`
        // } else {
        //   return '50vh'
        // }
        if (window.screen.availWidth <= 800) {
          return '100%'
        } else {
          return '50vh'
        }
      }
    },
    methods: {
      ...mapActions(['showInfoAlert']),
      openMessage(message) {
        this.selectedMessage = message
        this.showViewMessageForm = true
      },
      handleCloseViewMessageForm2() {
        this.showViewMessageForm = false
      },
      formatDate(dateTime) {
        return `${getDateTime(dateTime, true)} ВСВ`
      },
      formatUser(user) {
        // console.log(user)
        let orgData = []
        user.roles.forEach(role => {
          orgData.push(`${role.organization} - ${translate(role.type)}`)
        })
        return `${user.lastName} ${user.firstName} ${user.patronymic} - ${orgData.join(',')}`
      },
      formatSignal(signal) {
        return `${getDateTime(signal.earthquakeDate, true)} ВСВ - ${signal.latitude} с.ш. ${signal.longitude}, в.д. магнитуда - ${signal.magnitude ? signal.magnitude : 'не указано'}`
      },
      formatMessage(message) {
        return `${translate(message.type)} - от ${getDateTime(message.sent, true)} ВСВ, Отправитель ${message.sender}, адрес ${message.address ? message.address : '-'}`
      },
      async loadFilters() {
        let usersPromise = getUsers()
          .then(results => {
            let k = 1
            if (checkSuccess(results)) {
              this.users = results.payload
              this.users.forEach(u => {
                if (!u.id) {
                  u.id = k++
                }
              })
              // console.log('1', this.users[0])
            }
          })
        let signalsPromise =  getSignals()
          .then(results => {
            if (checkSuccess(results)) {
              this.signals = results.payload
            }
          })
        let messagesPromise =  getActualMessages()
          .then(results => {
            if (checkSuccess(results)) {
              this.messages = results.payload
            }
          })

        await Promise.all([usersPromise, signalsPromise, messagesPromise])
          .catch(() => {
            this.showInfoAlert('Не удалось загрузить фильтры')
          })

      },
      resize() {
        this.resizeDate = new Date()
      },
      resetFilters() {
        this.selectedUser = null
        this.selectedSignal = null
        this.selectedMessage = null
        // this.selectedDate = null//getUtcDateFromInput(getFormattedDate('UTC', new Date()).replaceAll(", ", "T"))
        this.filterDate = null
        this.filterTime = '00:00'
      },
      async refresh() {
        // let date = this.selectedDate //? new Date(dateInputToDateString(this.selectedDate)) : null
        let date = this.filterDate ? new Date(dateInputToDateString(this.filterDate + ' ' + this.filterTime )) : null
        await this.downloadMessages(date)
        this.searchOnTable()
      },
      searchOnTable() {
        this.filteredLogs = searchByName(this.logs, this.search)
      },
      async loadUser() {
        let result = await getUser(this.userId)
        if (result.code === 'SUCCESS') {
          this.user = result.payload
        }
      },
      async downloadMessages(date) {
        this.loading = true
        try {
          let result = await getTriggeringMessages(date)
          if (result.code === 'SUCCESS') {
            this.logs = result.payload
            this.filteredLogs = this.logs ? this.logs : []
            // console.log(this.logs.length)
          } else {
            this.logs = []
          }
        } finally {
          this.loading = false
        }
      }
    },
    async mounted() {
      this.loadFilters()
      // this.loadUser()
      // await this.downloadMessages(this.userId)
      // this.filtedLogs = this.logs
      let that = this
      addEventListener("resize", () => {that.resize()});
    }
  }
</script>

<style scoped>
  div.md-content table {
    max-height: 50vh;
    height: 50vh;
    /*overflow-y: auto;*/
    width: auto !important;
  }

  div.filter-containers div.md-field {
    margin: 4px 5px 12px !important;
  }



</style>